<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="
        text-dark-75
        font-weight-bolder
        text-hover-primary
        mb-1
        font-size-lg
        d-flex
        flex-row
        align-items-center
      "
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Ujian
              </span>
            </h3>
          </div>
          <div class="card-body pt-4">
            <!--          <template v-slot:header>-->
            <!--            <b-row>-->
            <!--              <b-col>-->
            <!--                <h6 class="mb-0">Daftar Ujian</h6>-->
            <!--                <b-skeleton width="25%" v-if="isLoading"></b-skeleton>-->
            <!--                <span class="mb-0" v-if="!isLoading && currentUjianList[0]">{{ currentUjianList[0].kurikulum.mapel.nama_mapel }}</span>-->
            <!--              </b-col>-->
            <!--              <b-col cols="auto">-->
            <!--                <b-button pill variant="warning" class="mr-1" @click="$router.back()">Kembali</b-button>-->
            <!--                <b-button pill variant="primary" tag="router-link" :to="`/mapel/${idKurikulum}/ujian/create/`">Tambah</b-button>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!--          </template>-->
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
              :items="currentUjianResult.data"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada ujian ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada ujian ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Deskripsi:
                </div>
                <div v-html="row.item.description"></div>
              </template>
              <template #cell(kode_ujian)="data">
                {{ data.item.kode_ujian }}
              </template>
              <template #cell(nama_ujian)="data">
                <div>
                  {{ data.item.nama }}
                </div>
              </template>
              <template #cell(status)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{
                    data.item.is_already_done
                      ? "Sudah dikerjakan"
                      : currentAttempt._id == data.item._id
                      ? "Sedang dikerjakan"
                      : "Belum dikerjakan"
                  }}
                </span>
              </template>
              <template #cell(waktu)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info">-->
                <!--                  {{ data.item.waktu }} Menit-->
                <!--                </b-badge>-->
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.time }} Menit
                </span>
              </template>
              <template #cell(tanggal)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-success
                    mr-1
                  "
                >
                  {{ data.item.opening_time | moment("dddd, LL HH:mm") }}
                </span>
                -
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-danger
                    ml-1
                  "
                >
                  {{ data.item.closing_time | moment("dddd, LL HH:mm") }}
                </span>
              </template>
              <template #cell(published)="data">
                <b-form-checkbox
                  v-model="data.item.is_published"
                  @input="togglePublish(data.item)"
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <span
                  @click="attemptUjian(data.item)"
                  v-if="
                    isSiswa &&
                    !currentAttempt.soal &&
                    new Date() >= Date.parse(data.item.opening_time) &&
                    new Date() <=
                      new Date(Date.parse(data.item.closing_time)).setMinutes(
                        new Date(
                          Date.parse(data.item.closing_time)
                        ).getMinutes() - data.item.time
                      )
                  "
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-sm
                    mr-1
                  "
                  >Mulai Ujian</span
                >
                <router-link
                  v-if="!isSiswa"
                  :to="`/ujian/${data.item._id}/soal`"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-sm
                    mr-1
                  "
                  >Detail Ujian</router-link
                >
                <router-link
                  v-if="!isSiswa"
                  :to="`/ujian/${data.item._id}/hasil`"
                  class="
                    btn btn-light-success
                    font-weight-bolder font-size-sm
                    mr-1
                  "
                  >Lihat Hasil</router-link
                >
                <router-link
                  v-if="
                    !isSiswa &&
                    new Date() > Date.parse(data.item.tanggal_ditutup) &&
                    data.item.soal.filter((item) => item.tipe == 'essay')
                      .length > 0
                  "
                  :to="`/mapel/${
                    idKurikulum ? idKurikulum : data.item.id_kurikulum
                  }/ujian/${data.item.id}/penilaian/`"
                  class="
                    btn btn-light-success
                    font-weight-bolder font-size-sm
                    mr-1
                  "
                  >Penilaian</router-link
                >

                <b-dropdown
                  v-if="!isSiswa"
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link
                        class="navi-link"
                        :to="`/ujian/${data.item._id}/edit`"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Ubah Ujian</span>
                      </router-link>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span
                        class="navi-link cursor-pointer"
                        @click="deleteUjian(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Ujian</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  UPDATE_UJIAN,
  GET_LIST_RESULT_EXAM,
} from "@/core/services/store/ujian.module";
import { GET_MAPEL } from "@/core/services/store/mapel.module";
import { GET_ONE_ATTEMPT } from "@/core/services/store/attempt.module";
export default {
  name: "UjianLIst",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fieldSiswa: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "_id",
          label: "Kode ujian",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama ujian",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_ujian",
          label: "Kode ujian",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama ujian",
          sortable: true,
        },
        // {
        //   key: 'waktu',
        //   label: "Waktu",
        //   sortable: true
        // },
        {
          key: "tanggal",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "published",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      selectedMapel: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar hasil ujian" }]);
    if (!this.isSiswa) {
      this.getData();
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentUjianResult"]),
    idUjian() {
      return this.$route.params.idUjian;
    },
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.$currentUser().role == "student";
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMapel() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_MAPEL)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Mata Pelajaran`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;

      const params = new URLSearchParams();
      //   params.append("limit", this.perPage);
      //   params.append("page", this.currentPage);
      this.$store
        .dispatch(GET_LIST_RESULT_EXAM, this.idUjian)
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentUjianResult.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    togglePublish(item) {
      // item.published = !item.published
      this.isLoading = true;

      this.$delete(item, "class_id");
      var newValue = item;

      newValue.is_published = item.is_published;

      this.$store
        .dispatch(UPDATE_UJIAN, newValue)
        .then((response) => {
          this.isLoading = false;
          this.$bvToast.toast(
            `Berhasil ${item.is_published ? "publish" : "unpublish"} ujian`,
            {
              title: response.message,
              variant: "success",
              solid: true,
            }
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Publish Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    attemptUjian(item) {
      const overlay = this.$loadingOverlay.show();
      this.$store
        .dispatch(GET_ONE_ATTEMPT, item._id)
        .then(() => {
          overlay.hide();
          this.$router.push("attempt");
        })
        .catch(() => {
          overlay.hide();
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
